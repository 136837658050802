@charset "UTF-8";
@font-face {
  font-family: 'basic';
  src: url(23f30d5aead979c76f66c41f452ced1f.eot);
  src: url(23f30d5aead979c76f66c41f452ced1f.eot) format("embedded-opentype"), url(7b411b910306dd659ab3c68fb84666d2.woff2) format("woff2"), url(5025969ce1f3634128b7472323658a71.woff) format("woff"), url(109712a874d3ba9b19eead46e95d295a.ttf) format("truetype"), url(e3e361760fd60c3b456e89466d1b1429.svg) format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'basic';
    src: url('../font/basic.svg?39591723#basic') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "basic";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-search:before {
  content: '\e800'; }

/* '' */
.icon-dot-3:before {
  content: '\e801'; }

/* '' */
.icon-cancel-1:before {
  content: '\e802'; }

/* '' */
.icon-expand:before {
  content: '\e803'; }

/* '' */
.icon-picture:before {
  content: '\e804'; }

/* '' */
.icon-left:before {
  content: '\e805'; }

/* '' */
.icon-right:before {
  content: '\e806'; }

/* '' */
.icon-down:before {
  content: '\e807'; }

/* '' */
.icon-up:before {
  content: '\e808'; }

/* '' */
.icon-basket-time:before {
  content: '\e80a'; }

/* '' */
.icon-basket-check:before {
  content: '\e80b'; }

/* '' */
.icon-human-check:before {
  content: '\e80c'; }

/* '' */
.icon-money-daller2:before {
  content: '\e80d'; }

/* '' */
.icon-alert-important:before {
  content: '\e80e'; }

/* '' */
.icon-ticket:before {
  content: '\e80f'; }

/* '' */
.icon-ticket2:before {
  content: '\e810'; }

/* '' */
.icon-datepicker:before {
  content: '\e811'; }

/* '' */
.icon-ticket-refresh:before {
  content: '\e812'; }

/* '' */
.icon-pen-ruler:before {
  content: '\e813'; }

/* '' */
.icon-ticket-check:before {
  content: '\e814'; }

/* '' */
.icon-trash-can:before {
  content: '\e815'; }

/* '' */
.icon-pen-docs:before {
  content: '\e816'; }

/* '' */
.icon-alert-write:before {
  content: '\e817'; }

/* '' */
.icon-logo-icon:before {
  content: '\e818'; }

/* '' */
.icon-mic:before {
  content: '\e819'; }

/* '' */
.icon-peoples:before {
  content: '\e81a'; }

/* '' */
.icon-spicker-fill:before {
  content: '\e81b'; }

/* '' */
.icon-notice--light:before {
  content: '\e81c'; }

/* '' */
.icon-blog:before {
  content: '\e81d'; }

/* '' */
.icon-graduated:before {
  content: '\e81e'; }

/* '' */
.icon-group:before {
  content: '\e81f'; }

/* '' */
.icon-profile:before {
  content: '\e820'; }

/* '' */
.icon-store:before {
  content: '\e821'; }

/* '' */
.icon-profile2:before {
  content: '\e822'; }

/* '' */
.icon-paper-doc:before {
  content: '\e823'; }

/* '' */
.icon-peoples--bold:before {
  content: '\e824'; }

/* '' */
.icon-bag:before {
  content: '\e825'; }

/* '' */
.icon-chart:before {
  content: '\e826'; }

/* '' */
.icon-fish:before {
  content: '\e827'; }

/* '' */
.icon-location:before {
  content: '\e828'; }

/* '' */
.icon-time-book:before {
  content: '\e829'; }

/* '' */
.icon-bike:before {
  content: '\e82a'; }

/* '' */
.icon-camera:before {
  content: '\e82b'; }

/* '' */
.icon-spoon:before {
  content: '\e82c'; }

/* '' */
.icon-morning:before {
  content: '\e82e'; }

/* '' */
.icon-night:before {
  content: '\e82f'; }

/* '' */
.icon-fish2:before {
  content: '\e830'; }

/* '' */
.icon-back:before {
  content: '\e831'; }

/* '' */
.icon-box-1:before {
  content: '\e832'; }

/* '' */
.icon-check-circle:before {
  content: '\e833'; }

/* '' */
.icon-paper-air:before {
  content: '\e834'; }

/* '' */
.icon-angle-double-left:before {
  content: '\e835'; }

/* '' */
.icon-angle-double-right:before {
  content: '\e836'; }

/* '' */
.icon-location--fill:before {
  content: '\e837'; }

/* '' */
.icon-spoon--fill:before {
  content: '\e838'; }

/* '' */
.icon-time-book--fill:before {
  content: '\e839'; }

/* '' */
.icon-bike--fill:before {
  content: '\e83a'; }

/* '' */
.icon-fish--fill:before {
  content: '\e83b'; }

/* '' */
.icon-camera--fill:before {
  content: '\e83c'; }

/* '' */
.icon-blog-1:before {
  content: '\e83d'; }

/* '' */
.icon-instagram-1:before {
  content: '\e83e'; }

/* '' */
.icon-kakao:before {
  content: '\e83f'; }

/* '' */
.icon-left-rotate:before {
  content: '\e840'; }

/* '' */
.icon-youtube:before {
  content: '\e841'; }

/* '' */
.icon-band:before {
  content: '\e842'; }

/* '' */
.icon-phone:before {
  content: '\e861'; }

/* '' */
.icon-down-dir:before {
  content: '\e877'; }

/* '' */
.icon-up-dir:before {
  content: '\e878'; }

/* '' */
.icon-left-dir:before {
  content: '\e879'; }

/* '' */
.icon-right-dir:before {
  content: '\e87a'; }

/* '' */
.icon-company-man:before {
  content: '\e8c2'; }

/* '' */
.icon-pick:before {
  content: '\e8c3'; }

/* '' */
.icon-comment-1:before {
  content: '\e8c4'; }

/* '' */
.icon-info-1:before {
  content: '\e8c5'; }

/* '' */
.icon-chain:before {
  content: '\e8c6'; }

/* '' */
.icon-male-1:before {
  content: '\e8c7'; }

/* '' */
.icon-notice:before {
  content: '\e8c8'; }

/* '' */
.icon-certification:before {
  content: '\e8c9'; }

/* '' */
.icon-certification--line:before {
  content: '\e8ca'; }

/* '' */
.icon-twitter:before {
  content: '\f099'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-angle-left:before {
  content: '\f104'; }

/* '' */
.icon-angle-right:before {
  content: '\f105'; }

/* '' */
.icon-angle-up:before {
  content: '\f106'; }

/* '' */
.icon-angle-down:before {
  content: '\f107'; }

/* '' */
.icon-behance:before {
  content: '\f1b4'; }

/* '' */
.icon-instagram:before {
  content: '\f32d'; }

/* '' */
@font-face {
  src: url(efe36cb1e690638fc59af446ffc5e774.otf), url(47f711bd1524b9283aa92d3d74db16a8.ttf), url(afeaf6797b6286b72b613ababc563807.woff), url(9ab0544da4ed08ba58b22d235baf57ce.woff2);
  font-family: 'BebasNeue'; }

@font-face {
  font-family: 'Didot';
  src: url(7067455111b5c870fcc0e23b7cbec4c0.woff2) format("woff2"), url(d6ab7003c0d3d4d2ff28e951c1796886.woff) format("woff"), url(6315553812dc9a620b4b16fc5854d01c.ttf) format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Didot bold';
  src: url(1c412ae3028241f577ef74acba21bc93.woff2) format("woff2"), url(67c335b2fd2aea25a54039cc7f423fd6.woff) format("woff2"), url(9eacfc67171a859699ba1f042e30e81c.ttf) format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Avenir Next LT Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Regular"), url(cdb0db7ccf637dbaad7d803df7143629.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Condensed"), url(ab3f5772beffc8b904741a54011db0d9.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Condensed Italic"), url(98f8c4d83dbaade242d616cf1d6066e4.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Italic"), url(78c644f08f48ba0943c2ff5969fea006.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Ultra Light"), url(f9d611fb5f3e32a85480d0717a8e62a6.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Ultra Light Italic"), url(a37023e0419e4ac9ee79491e29583197.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Ultra Light Condensed"), url(c2ec9051c85e7be17b6eecbb90dd9c8c.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Ultra Light Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Ultra Light Condensed Italic"), url(3046fa3079cc26ee7b1ffe13e1fe2573.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Medium Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Medium Condensed"), url(b9c9ff21ed25163b0330de1a6afedfb8.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Medium Italic"), url(75eb7968bad84d61ee1aefdd3ca82f5d.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Medium Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Medium Condensed Italic"), url(59ee67464cc19f45042767868946f6c2.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Demi';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Demi"), url(a99af28e5dbe5bb805d07cce6ea81b8e.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Demi Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Demi Condensed"), url(e515e61392b9ed925d5b31b1509e83a0.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Demi Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Demi Condensed Italic"), url(bfc7b85dfb960bef8391a183ab8d71fa.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Demi Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Demi Italic"), url(6cad70bac21c2c4d54233f6d480e777b.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Bold"), url(cf93e03434fed38a1ef58b160519dda9.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Bold Condensed"), url(3ab6635ae57fc91ac6ca877d0ed71b92.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Bold Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Bold Condensed Italic"), url(efb1fbc92d3e6df9a9d6f55344f76929.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Heavy';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Heavy"), url(d9ad575eb985033d584830f074a9200f.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Heavy Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Heavy Italic"), url(1bca80aa7b05f1ab9af73ed3a478dd7d.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Heavy Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Heavy Condensed"), url(964c1da821e21059b52a30e89d820382.woff) format("woff"); }

@font-face {
  font-family: 'Avenir Next LT Pro Heavy Condensed Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Avenir Next LT Pro Heavy Condensed Italic"), url(2ec58153f59f6a725daeee054fd81cc9.woff) format("woff"); }

.container {
  width: 1080px;
  margin: 0 auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .row:after {
    display: block;
    width: 100%;
    clear: both;
    content: ''; }
  .row.v-center {
    align-items: center; }

.p1-row {
  width: calc(100% + 8px);
  margin-left: -4px;
  margin-right: -4px; }
  .p1-row > div {
    padding-left: 4px;
    padding-right: 4px; }

.p2-row {
  width: calc(100% + 14px);
  margin-left: -7px;
  margin-right: -7px; }
  .p2-row > div {
    padding-left: 7px;
    padding-right: 7px; }

.p3-row {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px; }
  .p3-row > div {
    padding-left: 15px;
    padding-right: 15px; }

.col {
  float: left; }

.col-1 {
  width: 8.33333%; }

.col-left-1 {
  padding-left: 8.33333%; }

.col-right-1 {
  padding-right: 8.33333%; }

.col-2 {
  width: 16.66667%; }

.col-left-2 {
  padding-left: 16.66667%; }

.col-right-2 {
  padding-right: 16.66667%; }

.col-3 {
  width: 25%; }

.col-left-3 {
  padding-left: 25%; }

.col-right-3 {
  padding-right: 25%; }

.col-4 {
  width: 33.33333%; }

.col-left-4 {
  padding-left: 33.33333%; }

.col-right-4 {
  padding-right: 33.33333%; }

.col-5 {
  width: 41.66667%; }

.col-left-5 {
  padding-left: 41.66667%; }

.col-right-5 {
  padding-right: 41.66667%; }

.col-6 {
  width: 50%; }

.col-left-6 {
  padding-left: 50%; }

.col-right-6 {
  padding-right: 50%; }

.col-7 {
  width: 58.33333%; }

.col-left-7 {
  padding-left: 58.33333%; }

.col-right-7 {
  padding-right: 58.33333%; }

.col-8 {
  width: 66.66667%; }

.col-left-8 {
  padding-left: 66.66667%; }

.col-right-8 {
  padding-right: 66.66667%; }

.col-9 {
  width: 75%; }

.col-left-9 {
  padding-left: 75%; }

.col-right-9 {
  padding-right: 75%; }

.col-10 {
  width: 83.33333%; }

.col-left-10 {
  padding-left: 83.33333%; }

.col-right-10 {
  padding-right: 83.33333%; }

.col-11 {
  width: 91.66667%; }

.col-left-11 {
  padding-left: 91.66667%; }

.col-right-11 {
  padding-right: 91.66667%; }

.col-12 {
  width: 100%; }

.col-left-12 {
  padding-left: 100%; }

.col-right-12 {
  padding-right: 100%; }

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-top: 16px; }
  .p1-row {
    width: 100%;
    margin-left: 0;
    margin-right: 0; }
  .p1-row > div {
    padding: 0; }
  .p2-row {
    width: calc(100% + 1rem);
    margin-left: -.5rem;
    margin-right: -.5rem; }
  .p2-row > div {
    padding-left: .5rem;
    padding-right: .5rem; } }

.btn {
  padding: .7rem 1.4rem;
  background: none;
  border-radius: 2px; }
  .btn-under {
    border: 1px solid #222337;
    border-bottom: 3px solid #222337;
    transition: 300ms ease; }
  .btn-under:hover {
    background: #494b76;
    color: #fff; }

.header {
  width: 100%;
  background: #fff;
  margin-bottom: 8px; }
  .header__top {
    padding: 1.6rem 0; }
  .header__top-row {
    align-items: center; }
  .header__bottom {
    border-top: 1px solid #ececec;
    border-bottom: 1px solid #888; }

@media screen and (max-width: 768px) {
  .header {
    margin-bottom: 0; }
    .header__top {
      padding: 1rem; }
    .header__top-row {
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .header__logo {
      display: inline-block;
      width: 70%; }
    .header__logo img {
      width: 100%; }
    .header__toggle {
      font-size: 2.5rem;
      line-height: 1em; } }

.footer {
  padding: 1.4rem 0;
  border-top: 1px solid #e3e7ee;
  background: #fff; }
  .footer__logo {
    display: inline-block; }
  .footer__logo img {
    width: 197px; }
  .footer__info {
    margin-top: 1rem;
    font-size: .85rem; }
  .footer__copyright {
    margin-top: .3rem;
    font-size: .7rem; }
  .footer__family {
    width: 100%; }
  .footer__family-select {
    width: 100%;
    height: 35px;
    padding: 0 1rem;
    color: #6b7483;
    border: 1px solid #dee3eb; }
  .footer__band {
    margin-top: 2rem;
    padding-left: .85rem; }

@media screen and (max-width: 768px) {
  .footer {
    padding: 1.4rem 1.2rem; }
    .footer__family-select {
      border: none;
      color: black; }
    .footer__info {
      margin-bottom: 1rem; }
    .footer__copyright {
      margin-top: 1rem; } }

.ly1-support {
  display: flex;
  width: 100%; }
  .ly1-support__left, .ly1-support__right {
    display: block;
    width: 50%;
    padding: 1rem 0;
    text-align: center;
    color: #fff; }
  .ly1-support__left {
    background-color: #84848f; }
  .ly1-support__right {
    background-color: #83d494; }

.ly2-support {
  position: relative;
  display: block;
  width: 100%;
  height: 190px;
  background: url(8ca787c329738ae9e15d38d951897614.png) center center/cover; }
  .ly2-support:after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    content: ''; }
  .ly2-support__header {
    padding: 1.2rem;
    color: #fff; }
  .ly2-support__title {
    margin-bottom: .5rem;
    font-weight: bold;
    font-size: 1.7rem; }
  .ly2-support__info {
    font-size: .85rem; }
  .ly2-support__body {
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 65px;
    background: #000;
    overflow: hidden; }
  .ly2-support__col {
    position: relative;
    width: calc(100% / 3);
    height: 100%;
    padding: 1.7rem 0;
    text-align: center;
    color: #fff; }
  .ly2-support__col a {
    position: relative;
    z-index: 3; }
  .ly2-support__bg img {
    position: absolute;
    left: 0;
    top: 0;
    width: 105%;
    height: 105%; }

@media screen and (max-width: 768px) {
  .ly2-support {
    height: 60vw;
    margin: 8px 0;
    background: url(de4451a1388b48e1ad2396c8ee7a9bca.png) center center/cover; }
    .ly2-support__header {
      padding: 1.8rem 1.2rem;
      margin-bottom: .8rem; }
    .ly2-support__title {
      font-size: 2.8rem; }
    .ly2-support__info {
      font-size: 1.8rem; }
    .ly2-support__body {
      height: 28%; }
    .ly2-support__col {
      padding: 2.2rem 0;
      font-size: 1.6rem; } }

.ly1-nav {
  width: 100%; }
  .ly1-nav__list {
    display: flex;
    align-items: center;
    width: 100%; }
  .ly1-nav__item {
    position: relative;
    display: inline-block; }
  .ly1-nav__link {
    display: inline-block;
    padding: .9rem 1.8rem;
    font-weight: bold; }
  .ly1-nav__item--active .ly1-nav__link {
    background: #83d494;
    color: white;
    font-weight: normal;
    transition: 300ms ease; }
  .ly1-nav__sub {
    display: none;
    position: absolute;
    left: 50%;
    z-index: 100;
    top: 100%;
    width: 100px;
    transform: translateX(-50%);
    border-style: none; }
    .ly1-nav__sub-item {
      font-weight: bold;
      background: white;
      padding: 1rem 0;
      opacity: 0.98;
      text-align: center; }
    .ly1-nav__sub-item + .ly1-nav__sub-item {
      border-style: none; }
    .ly1-nav__sub-item:hover {
      background: lightgray;
      border-style: none;
      transition: 300ms ease; }

.ly1-follow {
  display: flex;
  align-items: center;
  width: 100%;
  padding: .5rem 1rem; }
  .ly1-follow__title {
    flex: 1;
    font-size: .8rem; }
  .ly1-follow__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 3; }
  .ly1-follow__item {
    display: inline-block;
    width: 25px;
    height: 30px;
    font-size: 20px; }
  .ly1-follow__link {
    color: #b4b4c3;
    transition: 300ms ease; }
  .ly1-follow__link:hover {
    color: #000; }

@media screen and (max-width: 768px) {
  .ly1-follow {
    padding: 0; }
    .ly1-follow__item {
      height: auto;
      font-size: 2.8rem; } }

.ly1-banner {
  position: relative;
  width: 100%;
  height: 420px; }
  .ly1-banner__slide {
    width: 100%;
    height: 100%; }
  .ly1-banner__slide-item {
    width: 100%;
    height: 100%; }
  .ly1-banner__slide-item-img {
    width: 100%;
    height: 100%; }
  .ly1-banner__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45px;
    background: rgba(255, 255, 255, 0.95); }
  .ly1-banner__title {
    padding: .5rem 1.2rem;
    font-size: 1.3rem; }

.ly2-banner {
  width: 100%;
  height: 45px; }
  .ly2-banner__image {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 768px) {
  .ly1-banner {
    height: 60vw;
    margin-bottom: 1rem; } }

.ly1-news {
  display: flex;
  background-color: #fff;
  border: 1px solid #dee3eb;
  height: 45px;
  padding-left: 1px;
  padding-right: 1px; }
  .ly1-news__content {
    display: flex;
    width: calc(100% - 40px);
    padding: .8rem 1.2rem; }
  .ly1-news__refresh {
    width: 40px;
    background: none;
    border: none;
    border-left: 1px solid #dee3eb;
    font-size: 1.1rem;
    color: #666; }
  .ly1-news__title {
    flex: 1;
    min-width: 95px; }
  .ly1-news__list {
    flex: 6;
    overflow: hidden; }
  .ly1-news__item {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

.card {
  width: 100%;
  background-color: #fff;
  border: 1px solid #dee3eb; }
  .card + .card {
    margin-top: 8px; }
  .card__header {
    display: flex;
    align-items: center;
    height: 45px;
    border-bottom: 1px solid #dee3eb; }
  .card__title {
    padding: 0 1.2rem; }
  .card__body {
    padding: 1.4rem 1.2rem; }
  .card.slide-card .card__header {
    justify-content: space-between; }
  .card.slide-card .slide-control {
    height: 100%;
    border-right: none; }
  .card.slide-card .slide-control__item--prev {
    border-right: 1px solid #dee3eb; }
  .card.slide-card .slide-control__item.slick-hidden {
    display: inline-block; }
  .card.slide-card .slide-card__position {
    height: 100%; }
  .card.padding-card {
    padding-left: 1.2rem;
    padding-right: 1.2rem; }
  .card.sm-card .card__header {
    height: calc(45px / 1.5); }
  .card .row .card__title,
  .card .row .card__body {
    padding-left: .4rem;
    padding-right: .4rem; }
  .card .row .card__body {
    padding-top: .4rem;
    padding-bottom: .4rem; }
  .card.list-card .card__body {
    padding: 1rem 0; }
  .card.list-card .card__footer {
    border-top: 1px solid #dee3eb;
    padding: 1rem 0;
    text-align: center;
    font-size: 1.2rem;
    color: #666; }
  .card.nopadding-card {
    padding: 0 !important; }

@media screen and (max-width: 768px) {
  .card {
    border-left: none;
    border-right: none;
    padding-left: 1.2rem;
    padding-right: 1.2rem; }
    .card__header {
      padding-top: 1rem;
      padding-bottom: 1rem;
      height: auto;
      border-bottom: none; }
    .card__title {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.6rem;
      font-family: 'Avenir Next LT Pro Demi'; }
    .card__body {
      padding: 0; } }

.review {
  display: block;
  width: 100%;
  height: 192px;
  margin-top: 1rem; }
  .review-list {
    height: calc(100% - 1rem);
    margin-top: -1rem; }
  .review__header {
    position: relative;
    width: 100%;
    height: 100px; }
  .review__header:before {
    display: block;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    content: ''; }
  .review__image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
  .review__body {
    padding: .5rem 0; }
  .review__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .9rem; }
  .review__tag {
    margin-top: .6rem;
    line-height: 1em; }
  .review__tag-item {
    display: inline-block;
    color: #999;
    font-size: .8rem; }

.ly2-review {
  display: block;
  margin-bottom: 1rem; }
  .ly2-review__image {
    width: 100%;
    height: 26vw;
    overflow: hidden; }
  .ly2-review__image img {
    width: 100%;
    height: 100%; }
  .ly2-review__title {
    margin-top: .5rem;
    font-size: 1.2rem; }

.ly1-board__list {
  height: calc(100% - .3rem);
  margin-top: -.3rem; }

.ly1-board__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: .3rem; }

.ly1-board__title {
  display: inline-block;
  width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: .85rem; }

.ly1-board__title:before {
  position: relative;
  top: 0rem;
  display: inline-block;
  margin-right: .4rem;
  color: red;
  font-size: 1.1rem;
  content: '+'; }

.ly1-board__date {
  display: inline-block;
  width: 65px;
  color: #818a98;
  font-size: .8rem; }

.ly2-board__header {
  display: flex;
  border-top: 1px solid #dee3eb;
  border-bottom: 1px solid #dee3eb;
  padding: .8rem 0; }

.ly2-board__tab {
  flex: 1;
  text-align: center;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #888;
  transition: 300ms ease; }

.ly2-board__tab--active {
  color: #000;
  font-weight: bold; }

.ly2-board__tab + .ly2-board__tab {
  border-left: 1px solid #dee3eb; }

.ly2-board__item {
  font-size: 1.2rem;
  padding: .8rem 0; }

.ly2-board__item + .ly2-board__item {
  border-top: 1px solid #dee3eb; }

.ly2-board__item-link {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ly2-board__more {
  border-top: 1px solid #dee3eb;
  padding: 1rem 0;
  text-align: center;
  font-size: 1.2rem;
  color: #999; }

.ly1-band {
  height: 188px;
  padding: 1.4rem;
  background: url(fc97adc40617a03092ba9cb1334e096c.png) no-repeat right bottom; }
  .ly1-band__phone {
    font-family: 'BebasNeue';
    font-size: 2.85rem;
    letter-spacing: -1px; }
  .ly1-band__sub {
    font-size: 1.3rem; }
  .ly1-band__info {
    font-size: .85rem;
    color: #888; }
  .ly1-band__btn {
    display: inline-block;
    margin-top: 1.2rem;
    padding: .5rem 1rem;
    border: none;
    border-radius: 300px;
    background: #82d495;
    color: #fff;
    transition: 300ms ease;
    cursor: pointer; }
  .ly1-band__btn:hover {
    background: #5cc775; }

.ly2-band__header {
  margin-bottom: .5rem; }

.ly2-band__body {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.ly2-band__phone {
  font-size: 1.7rem; }

.ly2-band__btn {
  padding: .5rem 1.5rem;
  border-radius: 300px;
  background: #82d495;
  color: #fff;
  font-size: 1.15rem;
  border: none;
  transition: 300ms ease; }

.ly2-band__btn:hover {
  background: #5cc775; }

@media screen and (max-width: 768px) {
  .ly1-band {
    position: relative;
    height: auto;
    padding: 1.2rem;
    background-size: 50% auto; }
    .ly1-band__phone {
      font-size: 4rem; }
    .ly1-band__sub {
      font-size: 2rem; }
    .ly1-band__info {
      font-size: 1.5rem; }
    .ly1-band__btn {
      position: absolute;
      bottom: 1.4rem;
      right: 1.4rem;
      padding: .8rem 2rem;
      margin-top: 0; } }

.ly1-office {
  display: flex;
  height: 150px; }
  .ly1-office__image {
    max-width: 160px;
    width: 100%;
    height: 100%; }
  .ly1-office__image img {
    width: 100%;
    height: 100%; }
  .ly1-office__content {
    width: calc(100% - 160px);
    padding: .5rem 1rem; }
  .ly1-office__title {
    font-size: 1.15rem; }
  .ly1-office__address {
    margin-top: .4rem;
    font-size: 0.85rem;
    color: #666; }
  .ly1-office__link {
    display: inline-block;
    margin-top: 2.2rem;
    font-size: 0.85rem;
    color: #666; }
  .ly1-office__link-icon {
    width: 25px; }

.ly2-office {
  padding: 1.6rem 0; }
  .ly2-office__list {
    padding: 0 1.2rem;
    overflow: hidden; }
  .ly2-office__list .slick-list {
    overflow: visible; }
  .ly2-office__item {
    width: 40vw !important;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 2rem; }
  .ly2-office__item-image {
    height: 32vw; }
  .ly2-office__item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .ly2-office__item-content {
    position: relative;
    background: #fff;
    padding: 1rem 1.2rem; }
  .ly2-office__item-title {
    font-size: 1.7rem; }
  .ly2-office__item-address {
    margin-top: .5rem;
    color: #666; }
  .ly2-office__item-phone {
    margin-top: .4rem;
    font-size: 1.4rem; }
  .ly2-office__item-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5%;
    top: -4vw;
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 5px 5px lightgray; }

.store.ly1-store .store__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem; }

.store.ly1-store .store__title {
  font-size: .85rem;
  font-weight: bold; }

.store.ly1-store .slide-control {
  border-left: none;
  border-right: none; }

.store.ly1-store .slide-control__item {
  width: 26px;
  height: 26px;
  border: 1px solid #e5e5e5; }

.store.ly1-store .slide-control__item--current {
  width: 40px;
  margin-right: 1rem;
  border: none; }

.store.ly1-store .slide-control__item--prev {
  border-right: none; }

.store-list {
  position: relative;
  height: calc(100% - 1.5rem);
  margin-top: -1.5rem; }

.store-item {
  display: inline-block;
  width: 100%;
  margin-top: 1.5rem; }

.store-item.ly1-store-item .store-item__header {
  width: 100%;
  height: 94px; }

.store-item.ly1-store-item .store-item__image {
  position: relative; }

.store-item.ly1-store-item .store-item__image:after {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  content: ''; }

.store-item.ly1-store-item .store-item__image,
.store-item.ly1-store-item .store-item__image img {
  width: 100%;
  height: 100%; }

.store-item.ly1-store-item .store-item__body {
  padding: .8rem 0;
  text-align: center;
  font-size: .85rem;
  line-height: 1.3em; }

@media screen and (max-width: 768px) {
  .store-item.ly1-store-item .store-item__header {
    width: 100%;
    height: 29.5vw; }
  .store-item.ly1-store-item .store-item__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.1rem;
    text-align: center;
    color: #666; }
  .store-item.ly1-store-item .store-item__price {
    margin-top: .5rem;
    font-weight: bold;
    font-size: 1.1rem; } }

.intro-main {
  padding: 1.9rem 0; }
  .intro-main__title {
    font-family: 'Avenir Next LT Pro Bold';
    font-size: 2.5rem;
    color: #404040; }
  .intro-main__sub {
    margin-top: .5rem;
    font-size: 1.4rem;
    line-height: 1.2em;
    color: #404040; }
  .intro-main__info {
    margin-top: .5rem;
    font-size: .85rem;
    color: #666; }
  .intro-main__menu {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center; }
  .intro-main__menu-btn {
    font-size: .8rem; }
  .intro-main__ceo {
    text-align: right; }
  .intro-main__ceo-company {
    color: #999;
    font-size: .8rem;
    line-height: 1em; }
  .intro-main__ceo-name {
    font-size: 1.3rem; }
  .intro-main__ceo-image {
    position: relative;
    width: 100%;
    height: 100%; }
  .intro-main__ceo-image img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 71%; }

.intro-sub {
  background: #f4f4f4;
  padding-top: 2.6rem; }
  .intro-sub__header {
    padding-top: 2.5rem;
    padding-bottom: 2rem; }
  .intro-sub__title, .intro-sub__title-sub {
    display: inline-block;
    color: #404040; }
  .intro-sub__title {
    font-size: 2rem;
    margin-right: 1.5rem; }
  .intro-sub__title-sub {
    font-size: 1.1rem; }
  .intro-sub__body {
    font-size: 1.2rem;
    color: #666; }
  .intro-sub__body p + p {
    margin-top: 2.2rem; }
  .intro-sub__footer {
    text-align: right; }
  .intro-sub__sign img {
    width: 220px; }
  .intro-sub__ceo {
    margin-top: -3rem; }
  .intro-sub__ceo-name {
    font-size: 1.5rem; }
  .intro-sub__ceo-image {
    font-size: 0; }

@media screen and (max-width: 768px) {
  .intro-main {
    width: 100%;
    padding: 1.6rem 1.2rem; }
    .intro-main__title {
      font-size: 1.6rem; }
    .intro-main__sub {
      font-size: 1.05rem; }
    .intro-main__menu {
      margin-top: 0;
      padding: 0 4rem; }
    .intro-main__info {
      padding-right: .4rem; }
    .intro-main__ceo {
      position: relative;
      padding-top: 4rem; }
    .intro-main__ceo-sign {
      position: absolute;
      right: 0;
      top: 0; }
    .intro-main__ceo-name {
      font-size: 1.5rem; }
    .intro-main__ceo-sign img {
      width: 100px; }
    .intro-main__ceo-image img {
      width: 100%; }
  .intro-sub__sign img {
    width: 150px; }
  .intro-sub__ceo-image {
    text-align: center; }
    .intro-sub__ceo-image img {
      width: 70%; }
  .ly1-group__image img {
    width: 100%; } }

.ly1-history {
  background: #fff;
  padding-bottom: 7rem; }
  .ly1-history__header {
    padding: 3.2rem; }
  .ly1-history__title {
    font-size: 1.2rem; }
  .ly1-history__title span {
    display: inline-block;
    margin: 0 .5rem;
    color: #e60011;
    font-family: 'Didot'; }
  .ly1-history__item {
    position: relative;
    width: 100%; }
  .ly1-history__item + .ly1-history__item {
    margin-top: 4.5rem; }
  .ly1-history__item-mark {
    position: absolute;
    left: 0;
    top: 0;
    width: calc((100% - 1100px) / 2); }
  .ly1-history__item-mark:before {
    display: block;
    width: 100%;
    height: 2px;
    background: #e60011;
    content: ''; }
  .ly1-history__item-year {
    position: absolute;
    top: -.2em;
    right: 0;
    padding: 0 1rem;
    background: #fff;
    font-size: 2.8rem;
    font-family: 'Avenir Next LT Pro Bold';
    line-height: 1em;
    color: #e60011; }
  .ly1-history__walk + .ly1-history__walk {
    margin-top: 1.8rem; }
  .ly1-history__walk-month {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    font-family: 'Avenir Next LT Pro Demi';
    color: #000; }
  .ly1-history__walk-list {
    color: #666; }
  .ly1-history__walk-list-item + .ly1-history__walk-list-item {
    margin-top: .2rem; }
  .ly1-history__walk-list-item:before {
    display: inline-block;
    margin-right: .4rem;
    color: #e60011;
    content: '+'; }

@media screen and (max-width: 768px) {
  .ly1-history__item {
    padding-top: 4rem; }
  .ly1-history__item-year {
    position: static;
    padding: 1rem 0; } }

.ly1-group {
  padding: 6rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAIAAABLbSncAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4NkIxNjRDMThFNUExMUU5ODAwRjlCNTJDMTBDQzFBRCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4NkIxNjRDMjhFNUExMUU5ODAwRjlCNTJDMTBDQzFBRCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjg2QjE2NEJGOEU1QTExRTk4MDBGOUI1MkMxMENDMUFEIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjg2QjE2NEMwOEU1QTExRTk4MDBGOUI1MkMxMENDMUFEIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+cld9ZAAAAB9JREFUeNpifP/+PQM2wALE7OzsmBJMDDjAgEoABBgAQQQC9Jh+3dQAAAAASUVORK5CYII=) center center; }
  .ly1-group__image + .ly1-group__image {
    margin-top: 7rem; }

.ly1-contact {
  background: #fff; }
  .ly1-contact__header {
    padding: 6rem 0; }
  .ly1-contact__title {
    font-size: 1.7rem;
    font-family: 'Avenir Next LT Pro Bold'; }
  .ly1-contact__body {
    background: url(7ac59e71382c6b253c9ce17e3ab7ac79.png) center center/cover; }
  .ly1-contact__list {
    padding: 2.5rem;
    background: #fff; }
  .ly1-contact__item {
    display: block;
    width: 100%; }
  .ly1-contact__item:before {
    display: block;
    width: 30px;
    height: 3px;
    background: #e60011;
    margin-bottom: 1rem;
    content: ''; }
  .ly1-contact__item + .ly1-contact__item {
    margin-top: 4rem; }
  .ly1-contact__item-title {
    font-size: 1.9rem; }
  .ly1-contact__item-kakao {
    display: inline-block;
    padding-top: .4rem;
    margin-left: .5rem; }
  .ly1-contact__item-info {
    margin-top: 1rem; }
  .ly1-contact__item-phone {
    margin-top: 2.8rem;
    font-size: 2.4rem;
    font-family: 'Avenir Next LT Pro Demi'; }

.workspace-sub {
  padding-top: 3.64rem;
  background: white; }
  .workspace-sub__part {
    border-left: 0.143rem solid black;
    height: 10.8rem;
    width: 80.43rem;
    float: left; }
    .workspace-sub__part__theme {
      font-weight: bold;
      font-size: 1.57rem;
      margin-left: 2rem;
      margin-top: -0.4rem;
      float: left; }
    .workspace-sub__part__worker {
      margin-left: 7.65rem;
      float: left; }
    .workspace-sub__part__title {
      margin-left: 1.5rem;
      height: 10.8rem;
      float: left; }
      .workspace-sub__part__title__name {
        font-size: 1.23rem;
        font-weight: bold; }
    .workspace-sub__part__content {
      margin-left: 1.8rem;
      padding-top: 0.23rem;
      float: left;
      width: 34rem; }
      .workspace-sub__part__content__earth {
        float: left; }
      .workspace-sub__part__content__blog {
        margin-left: 0.57rem;
        font-weight: bold;
        color: red;
        text-decoration: underline;
        float: left;
        width: 31rem; }
      .workspace-sub__part__content__left {
        font-weight: bold;
        float: left;
        margin-left: -1.5rem; }
        .workspace-sub__part__content__left__call {
          padding-top: 2.14rem;
          width: 5.28rem; }
        .workspace-sub__part__content__left__address {
          padding-top: 0.3rem;
          width: 5.28rem; }
        .workspace-sub__part__content__left__ride {
          padding-top: 1.8rem;
          width: 5.28rem; }
      .workspace-sub__part__content__right {
        float: left;
        width: 28rem; }
        .workspace-sub__part__content__right__call {
          padding-top: 2.14rem; }
        .workspace-sub__part__content__right__address {
          padding-top: 0.3rem; }
        .workspace-sub__part__content__right__ride {
          padding-top: 1.8rem; }
  .workspace-sub__map {
    padding-top: 2.28rem;
    margin-bottom: 3.57rem;
    width: 80.43rem;
    height: 31.64rem;
    float: left; }
    .workspace-sub__map__view {
      float: right; }

@media screen and (max-width: 768px) {
  .workspace {
    padding: 0 1rem; }
    .workspace__title {
      margin-top: 1rem;
      background: white; }
    .workspace__detail {
      background: white; }
      .workspace__detail-image img {
        width: 100%; }
      .workspace__detail-imagedetail {
        font-weight: bold;
        margin-top: 0.5rem;
        margin-bottom: 1rem; }
        .workspace__detail-imagedetail span {
          font-weight: normal;
          color: #adadad; }
    .workspace__wherelink {
      font-weight: bold;
      color: #c50018;
      text-decoration: underline; }
      .workspace__wherelink img {
        width: 200%; }
    .workspace__wheredetail {
      margin-top: 2rem; }
      .workspace__wheredetail-title {
        font-weight: bold; }
    .workspace__map {
      margin-top: 1rem; }
      .workspace__map img {
        width: 100%; }
  .workspace-sub__theme {
    font-weight: bold;
    font-size: 1.6rem;
    padding: 1rem 0; } }

.teacher {
  padding-top: 3.64rem;
  background: white; }
  .teacher__background {
    background: #f5f5f5;
    height: 12rem; }
  .teacher__testteacher {
    margin-top: -14%;
    padding-left: 1.5%;
    margin-bottom: 6%; }
    .teacher__testteacher-face img {
      width: 100%; }
    .teacher__testteacher-content {
      padding-top: 0.5rem;
      color: #797979;
      font-weight: bold; }
      .teacher__testteacher-content span {
        color: black; }
  .teacher__part {
    border-left: 0.143rem solid black;
    margin-left: 0.5rem;
    height: 10.8rem;
    width: 18.14rem;
    float: left; }
    .teacher__part__theme {
      font-weight: bold;
      font-size: 1.57rem;
      margin-left: 1.43rem;
      margin-top: -0.4rem;
      float: left; }
  .teacher__whole {
    width: 58.8rem;
    float: left; }
    .teacher__whole__teach {
      margin-bottom: 10rem; }
      .teacher__whole__teach__title {
        font-weight: bold;
        margin-top: 2rem;
        margin-bottom: 1rem;
        font-size: 1.43rem; }
  .teacher__who {
    font-weight: bold;
    font-size: 1.071rem;
    margin: 1rem 0 1rem 0; }

@media screen and (max-width: 768px) {
  .teacher {
    padding-top: 0; }
    .teacher__background {
      height: 8rem; }
    .teacher img {
      width: 100%; }
    .teacher__title {
      font-weight: bold;
      background: #f2f4f7; }
    .teacher__part__theme {
      font-size: 2rem;
      margin-bottom: 1.8rem; }
    .teacher__whole__teach__title {
      margin-bottom: 2rem; } }

.broadnews {
  background: white;
  padding: 3.64rem 0 3rem 0; }
  .broadnews__main {
    font-weight: bold;
    font-size: 1.4rem; }
  .broadnews-item__title {
    font-weight: bold;
    font-size: 1.43rem; }
  .broadnews-item__content {
    padding-top: 2rem;
    color: #8e8e8e;
    width: 79%; }
  .broadnews-item__tag {
    padding-top: 1.5rem;
    font-weight: bold;
    color: #8e8e8e;
    font-size: 0.8571rem; }
  .broadnews__more {
    background: #333333;
    color: white;
    width: 16.43rem;
    height: 2.43rem;
    margin: 2rem 0 0 36.57rem;
    text-align: center;
    line-height: 2.2rem;
    cursor: pointer; }

.broadnews-sub {
  background: #f5f5f5;
  padding: 3rem 0 3rem 0;
  margin-top: 2rem; }
  .broadnews-sub-item__title {
    font-weight: bold;
    font-size: 1.43rem; }
  .broadnews-sub-item__body {
    margin-top: 2rem; }
  .broadnews-sub-item__content {
    margin-top: 1.5rem;
    margin-right: 1rem;
    width: 56%;
    color: #8e8e8e; }
  .broadnews-sub-item__tag {
    font-weight: bold;
    font-size: 0.8571rem;
    margin-top: 4rem;
    color: #8e8e8e; }

@media screen and (max-width: 768px) {
  .broadnews {
    padding-top: 0; }
    .broadnews__main {
      font-size: 2rem;
      background: #f2f4f7;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      padding-left: 1.5rem; }
    .broadnews-item__body {
      padding-left: 2rem; }
    .broadnews-item__image img {
      width: 100%;
      margin-top: 6rem; }
    .broadnews-item__title {
      width: 18rem;
      margin-top: 3rem; }
      .broadnews-item__title img {
        width: 5%; }
    .broadnews__more {
      margin: auto; }
  .broadnews-sub {
    margin-top: 5rem; }
    .broadnews-sub-item__title {
      width: 95%;
      margin-left: 1.5rem; }
      .broadnews-sub-item__title img {
        width: 3%; }
    .broadnews-sub-item__image {
      margin-right: 2rem; }
      .broadnews-sub-item__image img {
        width: 100%; }
    .broadnews-sub-item__content {
      width: 50%;
      margin: 0 0 0 1.5rem; }
    .broadnews-sub-item__image {
      margin-right: 0;
      width: 42%; }
    .broadnews-sub-item__tag {
      margin-top: 1.5rem; } }

.process {
  background: white; }
  .process__header {
    width: 76.71rem;
    height: 96.64rem;
    margin-top: 3.64rem; }
    .process__header__title {
      font-weight: bold;
      font-size: 1.4rem;
      width: 18.21rem;
      height: 96.64rem;
      float: left; }
    .process__header__content {
      width: 57.5rem;
      height: 96.64rem;
      float: left; }
      .process__header__content-smallcontent {
        height: 12.08rem;
        border-top: 0.07rem solid #cfcfcf; }
        .process__header__content-smallcontent-name {
          width: 23.2rem;
          height: 12.08rem;
          float: left;
          border-right: 0.07rem solid #cfcfcf; }
          .process__header__content-smallcontent-name-num {
            font-weight: bold;
            font-size: 2.14rem;
            margin: 0.8rem 0 0.5rem 0;
            color: #cfcfcf; }
          .process__header__content-smallcontent-name-where {
            font-size: 1.143rem; }
            .process__header__content-smallcontent-name-where span {
              font-size: 1rem; }
        .process__header__content-smallcontent-story {
          width: 34.3rem;
          height: 12.08rem;
          float: left; }
          .process__header__content-smallcontent-story-detail {
            margin: 3rem 0 0 2rem; }
            .process__header__content-smallcontent-story-detail span {
              font-weight: bold; }

@media screen and (max-width: 768px) {
  .process {
    background: none;
    padding: 0 1rem; }
    .process-item {
      display: flex;
      background: white; }
      .process-item__header, .process-item__body {
        padding: 1.5rem 0.8rem; }
      .process-item__header + .process-item__body {
        border-left: 1px solid #ededed; }
      .process-item__header {
        display: flex;
        width: 52%; }
      .process-item__index {
        margin-bottom: .8rem;
        font-size: 2.5rem;
        color: #999;
        font-weight: bold; }
      .process-item__contact {
        padding-left: .6rem;
        padding-top: .8rem; }
      .process-item__body {
        width: 50%; }
    .process-item + .process-item {
      border-top: 1px solid #cfcfcf; }
    .process__header {
      width: 100%;
      height: auto;
      margin: 0; }
      .process__header__content {
        width: 100%;
        height: auto; }
        .process__header__content-smallcontent {
          height: auto;
          flex-wrap: nowrap; }
          .process__header__content-smallcontent-name {
            width: 50%;
            height: 100%;
            float: none;
            border-right: none;
            padding-left: 1rem !important;
            margin-left: 0; }
          .process__header__content-smallcontent-story {
            width: 50%;
            height: 100%;
            border-left: 1px solid #cfcfcf;
            float: none; }
            .process__header__content-smallcontent-story-detail {
              margin: 0;
              padding: 1rem; }
      .process__header__title {
        font-size: 2rem;
        width: 100%;
        height: auto;
        padding-bottom: 1rem;
        float: none;
        border-bottom: 2px solid black; } }

.sidebar {
  position: absolute;
  right: -70vw;
  top: 0;
  width: 70vw;
  height: 100vh;
  background-color: #ffffff;
  transition: 300ms ease; }
  .sidebar--active {
    right: -22vw; }
  .sidebar__item + .sidebar__item {
    margin-top: 1rem; }
  .sidebar-nav {
    display: block;
    width: 100%; }
    .sidebar-nav__list {
      width: 100%; }
    .sidebar-nav__item {
      width: 100%; }
    .sidebar-nav__link {
      display: block;
      width: 100%;
      padding: 1.2rem 1rem; }

.deep__sidebar {
  margin-left: 3rem; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none; }

*:before,
*:after {
  box-sizing: border-box; }

body, html {
  font-size: 14px;
  font-family: 'Noto Sans KR', sans-serif; }

button {
  cursor: pointer; }

button, input, textarea, select {
  font-family: 'Noto Sans KR', sans-serif; }

a {
  text-decoration: none;
  color: inherit; }

body {
  background: #f2f4f7; }

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 50;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden; }
  .modal--active {
    opacity: 1;
    visibility: visible; }

.white {
  background: #fff; }

.page-left,
.page-right {
  display: block; }

.page-left {
  width: 744px; }

.page-right {
  width: 336px; }

.main-section.white-main-section {
  background: #fff; }

.main-section {
  margin-top: 8px; }

.slide-control {
  display: flex;
  height: 45px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5; }
  .slide-control--noline {
    border: none; }
  .slide-control__item {
    width: 40px;
    background: none;
    border: none;
    text-align: center;
    transition: 300ms ease; }
  .slide-control__item:not(.slide-control__item--current):hover {
    background: #efefef; }
  .slide-control__item--current {
    padding: .4rem;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    font-size: .6rem; }
  .slide-control__item--current-size {
    position: relative;
    width: 100%;
    height: 100%; }
  .slide-control__item--current-size:before {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 1px;
    height: 100%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #666;
    content: ''; }
  .slide-control-value--current {
    position: absolute;
    left: 0;
    top: 0; }
  .slide-control-value--total {
    position: absolute;
    right: 0;
    bottom: 0; }

.slick-list, .slick-slide, .slick-track, .slick-slide > div {
  height: 100%; }

.line {
  display: flex;
  align-items: center; }
  .line span {
    display: flex;
    align-items: center; }
  .line span + span:before {
    display: inline-block;
    width: 1px;
    height: 1em;
    margin: 0 .6rem;
    background: #c3c3c3;
    content: ''; }

@media screen and (max-width: 768px) {
  body, html {
    font-size: 10px; }
  .page-left, .page-right {
    width: 100%; }
  .main-section + .main-section {
    margin-top: 0px; } }
